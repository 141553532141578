/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  Button,
  Pagination,
  Modal,
  Form,
  Row,
  Col,
  Container,
  Alert,
  Carousel,
  InputGroup,
  FormControl,
  Overlay,
} from "react-bootstrap";
import {
  FaEdit,
  FaRegTimesCircle,
  FaPlusCircle,
  FaInfoCircle,
  FaEye,
  FaTrashAlt,
  FaImages,
  FaRegEye,
  FaSearch,
  FaStar,
  FaPencilAlt,
  FaShare,
} from "react-icons/fa";
import axios from "axios";
import "./InmueblesComponent.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import config from "./../../../../../config.json";
const apiUrl = config.apiUrl;
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import Tooltip from "@mui/material/Tooltip";
import imagen from "../../../../../public/images/no_result_inmueble.png";
import AsyncSelect from "react-select/async";
import { CacheProvider } from "@emotion/react";
import PaginationBar from "../../FiltradoPage/Paginacion/Paginacion";
import * as Sentry from "@sentry/react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import {
  APIProvider,
  ControlPosition,
  MapControl,
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
const API_KEY =
  globalThis.GOOGLE_MAPS_API_KEY ?? "AIzaSyDf85TZstKYQCtDRhb8rSOKx6z01Dd6KVI";

const InmueblesComponent = ({ iduser, jwt }) => {
  // const formatosValidos = [
  //   // Avenida
  //   "Avenida XXXX [altura]",
  //   "Avenida XXX [altura]",
  //   "Avenida XX [altura]",
  //   "Avenida X [altura]",

  //   // Avenida (abreviada como Av)

  //   "Av XXXX [altura]",
  //   "Av XXX [altura]",
  //   "Av XX [altura]",
  //   "Av X [altura]",

  //   // Calle
  //   "Calle XXXX [altura]",
  //   "Calle XXX [altura]",
  //   "Calle XX [altura]",
  //   "Calle X [altura]",

  //   // Calle (esquina)
  //   "Calle XXX esquina XXX",
  //   "Calle XX esquina XX",
  //   "Calle X esquina X",
  // ];

  const [showOverlay, setShowOverlay] = useState(false);
  const [originalFileNames, setOriginalFileNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [editInmueble, setEditInmueble] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showError, setShowError] = useState(false);

  const [userData, setUserData] = useState({});

  const [propertyReq, setPropertyReq] = useState({
    recibosueldo: false,
    garantia: false,
    seguro: false,
  });

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const mapRef = useRef(null);

  const handleCheckboxChange = (field, target) => {
    let change = false;
    let value = "";

    if (field == "recibosueldo") {
      value = "Recibo de sueldo";

      if (!propertyData.recibosueldo) {
        change = true;
      } else {
        setPropertyReq({
          ...propertyReq,
          [field]: false,
        });
      }

      if (target != null) {
        setPropertyData({
          ...propertyData,
          recibosueldo: target,
        });
      }
    }

    if (field == "garantia") {
      value = "Garantía de propietario";

      if (!propertyData.garantia) {
        change = true;
      } else {
        setPropertyReq({
          ...propertyReq,
          [field]: false,
        });
      }

      if (target != null) {
        setPropertyData({
          ...propertyData,
          garantia: target,
        });
      }
    }

    if (field == "seguro") {
      value = "Seguro de caución";

      if (!propertyData.seguro) {
        change = true;
      } else {
        setPropertyReq({
          ...propertyReq,
          [field]: false,
        });
      }

      if (target != null) {
        setPropertyData({
          ...propertyData,
          seguro: target,
        });
      }
    }

    if (change) {
      Swal.fire({
        title: `¿El requisito ${value} será excluyente?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sí",
        confirmButtonColor: "green",
        cancelButtonText: "No",
        background: "#202529",
        color: "white",
      }).then((result) => {
        if (result.isConfirmed) {
          setPropertyReq({
            ...propertyReq,
            [field]: true,
          });
        } else {
          setPropertyReq({
            ...propertyReq,
            [field]: false,
          });
        }
      });
    }
  };

  const [isModalVisible, setModalVisibility] = useState(false);

  const handleShowModal = () => setModalVisibility(true);
  const handleCloseModal = () => setModalVisibility(false);

  const navigate = useNavigate();

  const [userBlock, setUserBlock] = useState(false);

  const [loadingInmueble, setLoadingInmueble] = useState(false);

  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

  const [imagePreviews, setImagePreviews] = useState([]);
  const [images, setImages] = useState([]);

  const [showRequisitos, setshowRequisitos] = useState(false);
  const [valorCargaImagen, setValorCargaImagen] = useState("0"); // Estado para el valor que se mostrará entre paréntesis

  // New state for video
  const [totalPhotos, setTotalPhotos] = useState(0);

  const [showGarageInput, setShowGarageInput] = useState(false);
  const [showExpensasInput, setShowExpensasInput] = useState(false);
  const [garageCount, setGarageCount] = useState(1);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showCircle, setShowCircle] = useState(false); // Nuevo estado para controlar la visibilidad del círculo
  const [showCarousel, setShowCarousel] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [initialCarouselIndex, setInitialCarouselIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Agregamos el estado para controlar la carga

  const [countPublishes, setCountPublishes] = useState(0); // Agregamos el estado para controlar la carga

  const fileInputRef = useRef(null);
  const [propertyTypeOptions3, setPropertyTypeOptions3] = useState([]);

  //Chip para tipos de inmuebles
  const chipStyles = {
    backgroundColor: "black", // Color de fondo del chip
    color: "white", // Color del texto dentro del chip
    padding: "3px 10px 3px", // Espaciado dentro del chip
    borderRadius: "15px", // Bordes redondeados para el chip
    display: "inline-block", // Mostrar como inline-block para que el chip se ajuste al contenido
    marginBottom: "5px",
    fontSize: "11px",
  };

  //Chip para visibilidad
  const chipStyles2 = (publicado) => ({
    backgroundColor: `${publicado ? "green" : "red"}`, // Color de fondo del chip
    color: "white", // Color del texto dentro del chip
    padding: "3px 10px 3px", // Espaciado dentro del chip
    borderRadius: "15px", // Bordes redondeados para el chip
    display: "inline-block", // Mostrar como inline-block para que el chip se ajuste al contenido
    marginBottom: "5px",
    fontSize: "11px",
  });

  const handleEnviarWhatsapp = (titulo, direccion) => {
    // Mensaje predeterminado
    const message = `¡Hola! Estoy interesado en destacar el inmueble ${titulo} \n\n https://inmos.com.ar/inmueble/${direccion}`;

    const phoneNumber = 2317512310;
    // URL para abrir WhatsApp con el mensaje predefinido
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Redirigir a la URL de WhatsApp
    window.location.href = whatsappUrl;
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const files = event.dataTransfer.files;
    const droppedImages = Array.from(files).slice(0, 15);
    const newImages = [
      ...images,
      ...droppedImages.slice(0, 15 - images.length),
    ];

    // Create an array of image previews for the new images
    const imagePreviewsArray = newImages.map((file) =>
      URL.createObjectURL(file)
    );

    setImages(newImages);
    setImagePreviews(imagePreviewsArray);
    setTotalPhotos(imagePreviewsArray.length);
  };

  const handleAddImages2 = async (imageUrls) => {
    const newImages = [];
    const imagePreviewsArray = [];

    // Crear un array de promesas para manejar la carga de imágenes de forma paralela
    const promises = imageUrls.map(async (imageUrl) => {
      try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
          throw new Error(`Error al cargar la imagen: ${response.statusText}`);
        }

        const blob = await response.blob();

        // Extraer el nombre de archivo de la URL
        const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

        // Crear un nuevo objeto File desde el Blob con el nombre de archivo extraído
        const file = new File([blob], filename, {
          type: blob.type || "image/jpeg", // Usar el tipo MIME del blob, o un valor predeterminado
        });

        newImages.push(file);
      } catch (error) {
        Sentry.captureException(error);
      }
    });

    // Esperar a que todas las promesas se resuelvan
    await Promise.all(promises);

    // Ordenar las nuevas imágenes alfabéticamente por nombre de archivo
    newImages.sort((a, b) => a.name.localeCompare(b.name));

    newImages.forEach((file) => {
      imagePreviewsArray.push(URL.createObjectURL(file));
    });

    // Actualizar los estados con las nuevas imágenes y vistas previas
    setImages((prevImages) => [...prevImages, ...newImages]);
    setImagePreviews((prevPreviews) => [
      ...prevPreviews,
      ...imagePreviewsArray,
    ]);
    setTotalPhotos((prevTotal) => prevTotal + imagePreviewsArray.length);
  };

  const handleAddImages = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const droppedImages = Array.from(files).slice(0, 15);

      // Obtén el índice de la última imagen cargada para continuar desde allí
      const lastIndex = images.length;

      const newImages = [
        ...images,
        ...droppedImages.slice(0, 15 - images.length),
      ];

      // Modificar el nombre de cada archivo para agregar una letra mayúscula diferente al principio
      const updatedImages = newImages.map((file, index) => {
        const absoluteIndex = lastIndex + index;
        const fileNameParts = file.name.split(".");
        const letter = String.fromCharCode("A".charCodeAt(0) + absoluteIndex);
        setOriginalFileNames((prevNames) => [...prevNames, file.name]); // Almacena el nombre original
        const newName = letter + fileNameParts[0] + "." + fileNameParts[1];
        return new File([file], newName, { type: file.type });
      });

      // Create an array of image previews for the new images
      const imagePreviewsArray = updatedImages.map((file) =>
        URL.createObjectURL(file)
      );

      setImages(updatedImages);
      setImagePreviews(imagePreviewsArray);
      setTotalPhotos(imagePreviewsArray.length);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...images];
    const updatedPreviews = [...imagePreviews];

    // Eliminar la imagen del índice especificado
    updatedImages.splice(index, 1);
    updatedPreviews.splice(index, 1);

    setImages(updatedImages);
    setImagePreviews(updatedPreviews);

    // Si la imagen eliminada era la imagen seleccionada, también limpiamos la selección
    if (selectedImageIndex === index) {
      setSelectedImageIndex(null);
      setShowCircle(false);
    }

    // Si el carrusel está abierto y la imagen eliminada era la imagen seleccionada, actualizamos el estado para cerrarlo.
    if (showCarousel && selectedImageIndex === index) {
      setShowCarousel(false);
    }

    setTotalPhotos(updatedPreviews.length);
  };

  const handleViewImage = (index) => {
    setSelectedImageIndex(index); // Establecemos el índice seleccionado
    setShowCarousel(true);
  };

  const handleCloseCarousel = () => {
    setSelectedImageIndex(null); // Restablecemos el índice seleccionado al cerrar el carrusel
    setShowCarousel(false);
  };

  const handleImageClick = (index) => {
    if (selectedImageIndex === null) {
      setSelectedImageIndex(index);
      setShowCircle(true); // Mostrar el círculo en todas las imágenes cuando haces clic
    } else if (selectedImageIndex !== index) {
      const updatedImages = [...images];
      const updatedOriginalFileNames = [...originalFileNames];

      // Intercambiar imágenes y nombres de archivo
      const draggedImage = updatedImages[selectedImageIndex];
      const draggedFileName = updatedOriginalFileNames[selectedImageIndex];

      updatedImages[selectedImageIndex] = updatedImages[index];
      updatedOriginalFileNames[selectedImageIndex] =
        updatedOriginalFileNames[index];

      updatedImages[index] = draggedImage;
      updatedOriginalFileNames[index] = draggedFileName;

      // Actualizar la primera letra de los nombres de archivo
      const updatedImagesWithNewNames = updatedImages.map((file, i) => {
        const fileNameParts = file.name.split(".");
        const letter = String.fromCharCode("A".charCodeAt(0) + i);
        const newName =
          letter + fileNameParts[0].slice(1) + "." + fileNameParts[1];
        return new File([file], newName, { type: file.type });
      });

      // Create an array of image previews for the new order
      const imagePreviewsArray = updatedImagesWithNewNames.map((file) =>
        URL.createObjectURL(file)
      );

      setImages(updatedImagesWithNewNames);
      setOriginalFileNames(updatedOriginalFileNames);
      setImagePreviews(imagePreviewsArray);
      setSelectedImageIndex(null);
      setShowCircle(false);
    } else {
      setSelectedImageIndex(null);
      setShowCircle(false);
    }
  };

  const formatNumber = (number) => {
    return number.toLocaleString("es-ES");
  };

  const [propertyData, setPropertyData] = useState({
    id: "",
    titulo: "",
    descripcion: "",
    tipo: "",
    direccion: "",
    area: "",
    dormitorios: "",
    localidad: "",
    telefono: "",
    banios: "",
    precio: "",
    precioexpensas: "",
    cantgaraje: "",
    cochera: false,
    apto_mascotas: false,
    patio: false,
    balcon: false,
    expensas: false,
    temporal: "",
    destacado: false,
    requisitos: false,
    recibosueldo: false,
    garantia: false,
    seguro: false,
    moneda: "",
    nombreusuario: "",
    publicado: true,
    tipousuario: "",
    imagesurl: "",
    alquiler: true,
    venta: false,
    precioventa: "",
    monedaventa: "",
    localidad_maps: {
      latitude: null,
      longitude: null,
    },
  });

  const resetForm = () => {
    setPropertyData({
      id: "",
      titulo: "",
      descripcion: "",
      tipo: "",
      direccion: "",
      area: "",
      dormitorios: "",
      localidad: "",
      telefono: "",
      banios: "",
      precio: "",
      precioexpensas: "",
      cantgaraje: "",
      cochera: false,
      apto_mascotas: false,
      patio: false,
      balcon: false,
      expensas: false,
      temporal: "",
      destacado: false,
      requisitos: false,
      recibosueldo: false,
      garantia: false,
      seguro: false,
      moneda: "",
      publicado: true,
      tipousuario: "",
      imagesurl: "",
      alquiler: true,
      venta: false,
      precioventa: "",
      monedaventa: "",
      localidad_maps: {
        latitude: null,
        longitude: null,
      },
    });

    setImagePreviews([]);
  };

  const propertyTypeOptions = [
    { value: "", label: "Seleccione un tipo de inmueble" },
    { value: "Departamento", label: "Departamento" },
    { value: "Casa", label: "Casa" },
    { value: "PH", label: "PH" },
    { value: "Cochera", label: "Cochera" },
    { value: "Galpón", label: "Galpón" },
    { value: "Local comercial", label: "Local comercial" },
    { value: "Oficina", label: "Oficina" },
    { value: "Otros", label: "Otros" },
    { value: "Cualquiera", label: "Cualquiera" },
  ];

  const propertyTypeOptions2 = [
    { value: "", label: "Seleccione un tipo de inmueble" },
    { value: "Hotel", label: "Hotel" },
    { value: "Departamento", label: "Departamento" },
    { value: "Casa", label: "Casa" },
    { value: "PH", label: "PH" },
    { value: "Quinta", label: "Quinta" },
    { value: "Cochera", label: "Cochera" },
    { value: "Hostel", label: "Hostel" },
    { value: "Oficina", label: "Oficina" },
    { value: "Pensiones/Habitaciones", label: "Pensiones/Habitaciones" },
    { value: "Otros", label: "Otros" },
    { value: "Cualquiera", label: "Cualquiera" },
  ];

  const propertyTypeMonedas = [
    { value: "", label: "Selecione una moneda" },
    { value: "ARS", label: "ARS" },
    { value: "USD", label: "USD" },
  ];

  const handleModalOpen = (property, urls) => {
    if (property) {
      setPropertyData(property);

      handleAddImages2(urls);
      setIsEditing(true);

      if (property.cochera) {
        setShowGarageInput(true);
      }

      if (property.expensas) {
        setShowExpensasInput(true);
      }

      if (property.requisitos) {
        setshowRequisitos(true);

        setPropertyReq({
          ...propertyReq,
          garantia: property.Ogarantiapropietario,
          recibosueldo: property.Orecibosueldo,
          seguro: property.Osegurocaucion,
        });
      }
    } else {
      setIsEditing(false);
      setPropertyData({
        // Aquí establece los valores iniciales para agregar un nuevo inmueble
        id: "",
        titulo: "",
        descripcion: "",
        tipo: "",
        direccion: "",
        area: "",
        dormitorios: "",
        localidad: "",
        telefono: userData.telefono,
        banios: "",
        precio: "",
        precioexpensas: "",
        cantgaraje: "",
        cochera: false,
        apto_mascotas: false,
        patio: false,
        balcon: false,
        expensas: false,
        temporal: "",
        destacado: false,
        requisitos: false,
        recibosueldo: false,
        garantia: false,
        seguro: false,
        moneda: "",
        nombreusuario: "",
        publicado: true,
        tipousuario: "",
        imagesurl: "",
        alquiler: true,
        venta: false,
        precioventa: "",
        monedaventa: "",
        localidad_maps: {
          latitude: null,
          longitude: null,
        },
      });
    }
    setShowModal(true);
  };

  const handleModalClose = () => {
    resetForm();
    setImagePreviews([]);
    setImages([]);
    setTotalPhotos(0);
    setShowModal(false);
  };

  const showSuccessAlert = (message) => {
    Swal.fire({
      icon: "success",
      title: "Éxito",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
      text: message,
      willOpen: () => {
        document.querySelector(".swal2-container").style.backgroundColor =
          "#202529";
      },
    }).then((final) => {
      reloadPage();
    });
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const showErrorAlert = (error) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: error,
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const showErrorImages = () => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No se puede subir un inmueble sin imagenes.",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const handleCocheraChange = (event) => {
    const isChecked = event.target.checked;
    setShowGarageInput(isChecked);
    if (!isChecked) {
      setGarageCount(1);
    }
    setPropertyData({
      ...propertyData,
      cochera: isChecked,
    });
  };

  const handleExpensasChange = (event) => {
    const isChecked = event.target.checked;
    setShowExpensasInput(isChecked);
    setPropertyData({
      ...propertyData,
      expensas: isChecked,
    });
  };

  const handleAlquilerChange = (event) => {
    const isChecked = event.target.checked;
    setPropertyData({
      ...propertyData,
      alquiler: isChecked,
    });
  };

  const handleVentaChange = (event) => {
    const isChecked = event.target.checked;
    setPropertyData({
      ...propertyData,
      venta: isChecked,
    });
  };

  const handleRequisitosChange = (event) => {
    const isChecked = event.target.checked;
    setshowRequisitos(isChecked);
    setPropertyData({
      ...propertyData,
      requisitos: isChecked,
    });
  };

  const handleDropdownSelect3 = (selectedOption) => {
    setPropertyData({
      ...propertyData,
      localidad: selectedOption.value,
    });
  };

  const fetchLocalidades = async (inputValue = "") => {
    try {
      const response = await axios.get(
        "https://inmos.com.ar/jsons/localidades.json"
      );
      const localidades = response.data.localidades;
      const localidadesFormatted = localidades.map((localidad) => ({
        value: `${localidad.nombre}, ${localidad.provincia.nombre}`,
        label: `${localidad.nombre}, ${localidad.provincia.nombre}`,
      }));

      // Filtra las localidades según la entrada del usuario
      const filteredLocalidades = localidadesFormatted.filter((localidad) =>
        localidad.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      const uniqueLocalidades = filteredLocalidades.filter(
        (localidad, index, self) =>
          index === self.findIndex((l) => l.value === localidad.value)
      );

      return uniqueLocalidades;
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error al cargar las localidades:", error);
      return [];
    }
  };

  const handleSaveProperty = async () => {
    if (isEditing) {
      const result = await Swal.fire({
        title: "¿Estás seguro de que deseas editar el inmueble?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#8FA50F",
        cancelButtonColor: "gray",
        confirmButtonText: "Sí, editarlo",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        setEditInmueble(true);

        propertyData.Orecibosueldo = propertyReq.recibosueldo;
        propertyData.Ogarantiapropietario = propertyReq.garantia;
        propertyData.Osegurocaucion = propertyReq.seguro;
        propertyData.nombreusuario = userData.razonsocial
          ? userData.razonsocial
          : `${userData.nombre} ${userData.apellido}`;

        try {
          const urls = await uploadImagesEdit(propertyData.id);

          propertyData.imagesurl = urls;

          await axios.put(
            apiUrl + `/api/inmuebles/${propertyData.id}`,
            { data: propertyData },
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          );

          showSuccessAlert(
            "El inmueble se ha editado exitosamente. Se recargará la página para reflejar los cambios."
          );
          setEditInmueble(false);
        } catch (error) {
          Sentry.captureException(error);
          console.error("Hubo un error al modificar el inmueble:", error);
          showErrorAlert();
          // Resto del código de manejo de errores
        }
      } else {
        console.error(error);
      }
    } else {
      if (
        !propertyData.titulo ||
        !propertyData.descripcion ||
        propertyData.tipo === "" ||
        propertyData.localidad === "" ||
        propertyData.telefono === "" ||
        !propertyData.direccion ||
        !propertyData.area ||
        !propertyData.dormitorios ||
        !propertyData.banios ||
        !propertyData.telefono
      ) {
        setShowError(true);

        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }

      if (!propertyData.alquiler && !propertyData.venta) {
        setShowError(true);

        setTimeout(() => {
          setShowError(false);
        }, 3000);
        return;
      }

      setBotonDeshabilitado(true);

      // Lógica para agregar un nuevo inmueble
      const propertyDataToSend = {
        data: {
          tipo: propertyData.tipo,
          dormitorios: parseInt(propertyData.dormitorios),
          precio: parseInt(propertyData.precio),
          precioventa: parseInt(propertyData.precioventa),
          cochera: propertyData.cochera,
          apto_mascotas: propertyData.apto_mascotas,
          banios: parseInt(propertyData.banios),
          patio: propertyData.patio,
          balcon: propertyData.balcon,
          temporal: propertyData.temporal,
          titulo: propertyData.titulo,
          direccion: propertyData.direccion,
          area: propertyData.area.toString(),
          user: iduser.toString(),
          descripcion: propertyData.descripcion,
          destacado: propertyData.destacado,
          expensas: propertyData.expensas,
          localidad: propertyData.localidad,
          telefono: propertyData.telefono,
          cantgaraje: garageCount,
          precioexpensas: parseInt(propertyData.precioexpensas),
          requisitos: propertyData.requisitos,
          recibosueldo: propertyData.recibosueldo,
          garantia: propertyData.garantia,
          seguro: propertyData.seguro,
          moneda: propertyData.moneda,
          nombreusuario: userData.razonsocial
            ? userData.razonsocial
            : `${userData.nombre} ${userData.apellido}`,
          publicado: propertyData.publicado,
          tipousuario: userData.tipo,
          Orecibosueldo: propertyReq.recibosueldo,
          Ogarantiapropietario: propertyReq.garantia,
          Osegurocaucion: propertyReq.seguro,
          imagesurl: propertyData.imagesurl,
          alquiler: propertyData.alquiler,
          venta: propertyData.venta,
          monedaventa: propertyData.monedaventa,
          localidad_maps: {
            latitude: propertyData.localidad_maps.latitude,
            longitude: propertyData.localidad_maps.longitude,
          },
        },
      };

      addNewInmueble(propertyDataToSend);
    }
  };

  async function uploadImagesEdit() {
    try {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append("images", image);
      });
      const resp = await axios.post(
        `https://inmos-webapp.azurewebsites.net/api/v1/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "multipart/form-data",
          },

          onUploadProgress: (progressEvent) => {
            // Calcula el porcentaje de subida
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setValorCargaImagen(percentCompleted);
          },
        }
      );

      return resp.data.imagesUrl;
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error al cargar imágenes:", error);
      throw error;
    }
  }

  async function uploadImages() {
    try {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append("images", image);
      });
      const resp = await axios.post(
        `https://inmos-webapp.azurewebsites.net/api/v1/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "multipart/form-data",
          },

          onUploadProgress: (progressEvent) => {
            // Calcula el porcentaje de subida
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setValorCargaImagen(percentCompleted);
          },
        }
      );

      return resp.data.imagesUrl;
    } catch (error) {
      Sentry.captureException(error);
      showErrorAlert("Hubo un error al cargar las imagenes. Intente de nuevo.");
    }
  }

  const changeVisibility = async (newVisibility, id) => {
    const dataVisibility = {
      data: {
        publicado: newVisibility,
      },
    };

    try {
      await axios.put(apiUrl + `/api/inmuebles/${id}`, dataVisibility, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      showSuccessAlert("La visibilidad cambió correctamente.");
    } catch (error) {
      Sentry.captureException(error);
      showErrorAlert(
        "Hubo un error al modificar el inmueble, intente nuevamente."
      );
    }
  };

  async function addNewInmueble(propertyDataToSend) {
    if (
      !propertyDataToSend.data.precioexpensas &&
      propertyDataToSend.data.expensas
    ) {
      showErrorAlert("Asegurese de colocar el precio de las expensas");
      setBotonDeshabilitado(false);
    } else {
      if (
        !propertyDataToSend.data.cantgaraje &&
        propertyDataToSend.data.cochera
      ) {
        showErrorAlert("Asegurese de colocar la cantidad de cocheras");
        setBotonDeshabilitado(false);
      } else {
        if (images.length > 0) {
          try {
            setLoadingInmueble(true);
            const urls = await uploadImages();

            propertyDataToSend.data.imagesurl = urls;

            const response = await axios.post(
              apiUrl + "/api/inmuebles",
              propertyDataToSend,
              {
                headers: {
                  Authorization: `Bearer ${jwt}`,
                },
              }
            );

            showSuccessAlert("El inmueble se ha cargado correctamente.");

            setBotonDeshabilitado(false);
          } catch (error) {
            Sentry.captureException(error);
            if (error.code === "ERR_BAD_REQUEST") {
              if (error.response.status == 429) {
                showErrorAlertMax();
              } else {
                showErrorAlertDireccion();
              }

              setBotonDeshabilitado(false);
            } else {
              setBotonDeshabilitado(false);

              showErrorAlert(
                "Hubo un error al cargar el video. Intente de nuevo."
              );

              axios.delete(
                apiUrl + `/api/inmuebles/${response.data.inmueble.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                }
              );
            }
          }
        } else {
          setBotonDeshabilitado(false);

          showErrorImages();
        }
      }
    }

    setLoadingInmueble(false);
    setValorCargaImagen(0);
    setValorCarga(0);
  }

  const showErrorAlertDireccion = (message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Hubo un error al cargar el inmueble. Asegurate de colocar correctamente la dirección.",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const showErrorSinOpciones = (message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Hubo un error al cargar el inmueble. Asegurate de colocar correctamente la dirección.",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const showErrorAlertMax = (message) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "¡Llegaste al limite de publicaciones este mes! \n Actualiza tu plan o espera a que se renueve tu limite.",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const handleDeleteProperty = async (id) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        background: "#202529",
        color: "white",
        confirmButtonColor: "#2980b9",
        cancelButtonColor: "gray",
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        // El usuario confirmó la eliminación
        await axios.delete(apiUrl + `/api/inmuebles/${id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        showSuccessAlert("El inmueble se ha eliminado exitosamente.");
        // Actualizar la lista de propiedades después de eliminar una
      } else {
        // El usuario canceló la eliminación
        // Puedes agregar algún código aquí si es necesario
      }
    } catch (error) {
      Sentry.captureException(error);
      showErrorAlert(
        "Hubo un error al eliminar el inmueble, intente nuevamente."
      );
    }
  };

  const [properties, setProperties] = useState([]);

  const copyLinkToClipboard = (id) => {
    try {
      const el = document.createElement("textarea");
      el.value = `https://inmos.com.ar/inmueble/${id}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      Swal.fire({
        icon: "success",
        title: "Enlace copiado",
        text: "El enlace se ha copiado correctamente al portapapeles.",
        background: "#202529",
        color: "white",
        confirmButtonColor: "#2980b9",
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error al intentar copiar enlace:", error);
      Swal.fire({
        icon: "error",
        title: "Error al copiar enlace",
        text: "Hubo un problema al intentar copiar el enlace al portapapeles.",
        background: "#202529",
        color: "white",
        confirmButtonColor: "#2980b9",
      });
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/api/user/getUserById/${iduser}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        setUserData(response.data);

        if (JSON.stringify(userData).blocked) {
          navigate("/userblock");
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    };

    const fetchCountPublishes = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/api/user/getlimit/${iduser}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        setCountPublishes(response.data.count);
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/api/inmuebles/user/${iduser}`
        );
        const propertiesData = response.data.data.map((property) => ({
          id: property.id,
          ...property,
        }));

        if (propertiesData.length == 0) {
          <p className="mt-5">No se encontró ningun inmueble cargado.</p>;
        } else {
          setProperties(propertiesData);
        }

        setIsLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
      }
    };

    fetchUser();
    fetchData();
    fetchCountPublishes();
  }, []);

  const loadOptions = (inputValue, callback) => {
    fetchLocalidades(inputValue).then((options) => {
      callback(options);
    });
  };

  const [showPublished, setShowPublished] = useState(true);

  const filteredProperties = properties.filter((property) => {
    const searchId = Number(searchValue);
    return (
      (property.direccion.toLowerCase().includes(searchValue.toLowerCase()) ||
        (searchValue !== "" && !isNaN(searchId) && property.id === searchId)) &&
      (showPublished ? property.publicado : !property.publicado)
    );
  });

  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const textRef = useRef(null);

  const [showModal2, setShowModal2] = useState(true);
  const [showTourOnLogin, setShowTourOnLogin] = useState(
    localStorage.getItem("showTourOnLogin")
  );
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const startTour = useCallback(() => {
    introJs()
      .setOptions({
        steps: [
          {
            element: "#welcome-message",
            title: "Busqueda",
            intro: "Puedes buscar el inmueble por dirección escribiendo aca",
            position: "center",
          },

          {
            element: "#button-tour2",
            title: "Filtrado",
            intro:
              "Con este botón puedes filtrar los inmuebles por ocultos/publicados",
            position: "left",
          },
          {
            element: "#button-tour",
            title: "Agregar",
            intro: "Con este botón puedes agregar un inmueble",
            position: "left",
          },
          {
            element: "#text-tour",
            title: "Publicaciones",
            intro: "Se puede ver la cantidad de publicaciones disponibles",
            position: "left",
          },
        ],
        showProgress: true, // Muestra la barra de progreso
        showBullets: false, // No muestra los puntos
        tooltipClass: "my-custom-tooltip", // Aplica la clase CSS personalizada
        highlightClass: "my-highlight", // Aplica una clase CSS personalizada para resaltar elementos
        nextLabel: "Siguiente", // Texto del botón "Next"
        prevLabel: "Anterior", // Texto del botón "Previous"
        doneLabel: "Hecho", // Texto del botón "Done"
      })
      .start();
  }, []);

  useEffect(() => {
    if (showTourOnLogin !== true) {
      setShowModal2(true);
    }
  }, []);

  const handleStartTour = () => {
    if (dontShowAgain) {
      // Si el usuario marcó "No volver a mostrar", guardar false
      localStorage.setItem("showTourOnLogin", true);
    }
    startTour();
    setShowModal(false);
  };

  return (
    <Container fluid>
      {userData.estado == "Pago" && (
        <>
          <Row className="justify-content-center mb-3">
            <Col>
              <InputGroup
                className="custom-input-group"
                id="welcome-message"
                ref={inputRef}
              >
                <InputGroup.Text>
                  <FaSearch size="1em" color="#fcd088" />
                </InputGroup.Text>

                <FormControl
                  placeholder="Buscar inmueble por dirección"
                  aria-label="Buscar inmueble"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="mt-1" md={8} sm={8}>
              {!userBlock ? (
                <>
                  <a
                    className="inicio2"
                    onClick={() => handleModalOpen()}
                    style={{ marginRight: 10 }}
                    id="button-tour"
                    ref={buttonRef}
                  >
                    <FaPlusCircle style={{ marginRight: 10 }} />
                    Nuevo
                  </a>
                </>
              ) : (
                <>
                  <a
                    className="inicio2"
                    id="button-tour"
                    ref={buttonRef}
                    onClick={() => handleModalOpen()}
                    style={{ marginRight: 10, pointerEvents: "none" }}
                  >
                    <FaPlusCircle style={{ marginRight: 10 }} />
                    Nuevo (Bloqueado)
                  </a>
                </>
              )}

              <a
                className="inicio2"
                id="button-tour2"
                ref={buttonRef}
                onClick={() => setShowPublished((prev) => !prev)}
                style={{ marginLeft: 10 }}
              >
                <FaInfoCircle style={{ marginRight: "5px" }} />
                {showPublished ? "Ver ocultos" : "Ver publicados"}
              </a>
            </Col>

            <Col className="mt-1" style={{ textAlign: "right" }} sm={4} md={4}>
              <span
                style={{ fontWeight: "bold", fontSize: 12 }}
                id="text-tour"
                ref={textRef}
              >
                Publicaciones {countPublishes} /
                {userData.plan == "PREMIUM"
                  ? " Ilimitado"
                  : ` ${userData.limitPublishes}`}
              </span>
            </Col>
          </Row>
        </>
      )}

      <div className="cards-container">
        {filteredProperties.length == 0 && (
          <Row className="justify-content-center" style={{ marginTop: "0rem" }}>
            {isLoading ? ( // Mostrar el mensaje de carga solo si isLoading es true
              <Container className="d-flex flex-column align-items-center justify-content-center ">
                <div className="loader" style={{ marginTop: "10em" }}></div>
              </Container>
            ) : (
              // Mostrar los inmuebles si isLoading es false y hay propiedades cargadas
              properties.map((property) => (
                <div key={property.id}>
                  {/* Renderizar la información del inmueble aquí */}
                </div>
              ))
            )}
            {filteredProperties.length === 0 &&
              !isLoading &&
              userData.estado == "Pago" && (
                // Mostrar el mensaje y la imagen si no hay propiedades y no está cargando
                <div
                  className="text-center d-flex justify-content-center align-items-center"
                  style={{ marginTop: "10em" }}
                >
                  <img
                    src={imagen} // Reemplaza esto con la ruta de tu imagen
                    alt="Sin resultados"
                    style={{ maxWidth: "150px" }}
                  />
                  <p style={{ color: "white" }}>
                    No se encontró ningún <strong>inmueble</strong> cargado.
                  </p>
                </div>
              )}
          </Row>
        )}
        {filteredProperties.map((property) => (
          <Card
            key={property.id}
            style={{
              border: property.destacado
                ? "1px solid rgba(252, 78, 57,0.2)"
                : "none",
              boxShadow: property.destacado
                ? "0 0 4px #fcd088"
                : "0 0 4px white",
              minHeight: "500px",
              backgroundColor: "rgba(56,65,73,0.15)",
              color: "white",
            }}
          >
            <Carousel className="">
              {property.imagesurl &&
                property.imagesurl
                  .slice()
                  .sort((a, b) => a.localeCompare(b))
                  .map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          maxHeight: "350px",
                        }}
                        src={image}
                        alt={`Imagen ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
            </Carousel>
            <Card.Body>
              <Card.Title>
                {property.direccion}{" "}
                <span style={chipStyles}>{property.tipo} </span>{" "}
                <span style={chipStyles2(property.publicado)}>
                  <FaRegEye style={{ marginRight: "5px" }}></FaRegEye>
                  {property.publicado ? "Publicado" : "Oculto"}
                </span>
              </Card.Title>
              <Row className="justify-content-left">
                <Col xs="auto">
                  {" "}
                  <strong>Codigo:</strong> {property.id}
                  <br />
                  <strong>Titulo:</strong> {property.titulo}
                </Col>
              </Row>
              <Row className="justify-content-left border-top border-bottom pt-2 pb-2 mt-1">
                <Col xs="auto">
                  <Col xs="auto">
                    {" "}
                    <strong> Descripción:</strong> {property.descripcion}
                  </Col>
                </Col>
              </Row>
              <Row className="justify-content-left mt-1">
                <Col xs="auto">
                  {" "}
                  <strong>Venta:</strong> {property.venta ? "Sí" : "No"}{" "}
                </Col>
                <Col xs="auto">
                  {" "}
                  <strong>Alquiler:</strong> {property.alquiler ? "Sí" : "No"}{" "}
                </Col>
                {property.precioventa && (
                  <Col xs="auto">
                    <strong>Precio venta:</strong> $
                    {property.precioventa && formatNumber(property.precioventa)}
                  </Col>
                )}
                {property.precio && (
                  <Col xs="auto">
                    <strong>Precio alquiler:</strong> $
                    {property.precio && formatNumber(property.precio)}
                  </Col>
                )}
              </Row>
              <Row className="justify-content-left">
                <Col xs="auto">
                  {" "}
                  <strong>Superficie total: </strong> {property.area} m²
                </Col>
                <Col xs="auto">
                  {" "}
                  <strong>Dormitorios:</strong> {property.dormitorios}
                </Col>
                <Col xs="auto">
                  <strong>Baños:</strong> {property.banios}
                </Col>

                {property.moneda && (
                  <Col xs="auto">
                    <strong>Moneda alquiler:</strong> {property.moneda}
                  </Col>
                )}

                {property.monedaventa && (
                  <Col xs="auto">
                    <strong>Moneda venta:</strong> {property.monedaventa}
                  </Col>
                )}
              </Row>
              <Row className="justify-content-left">
                <Col xs="auto">
                  <strong>Cochera:</strong> {property.cochera ? "Sí" : "No"}{" "}
                </Col>
                {property.cochera && (
                  <Col xs="auto">
                    <strong>Cocheras:</strong> {property.cantgaraje} <br />
                  </Col>
                )}
              </Row>
              <Row className="justify-content-left">
                <Col xs="auto">
                  <strong>Expensas:</strong> {property.expensas ? "Sí" : "No"}{" "}
                </Col>
                {property.expensas && (
                  <Col xs="auto">
                    <strong>Precio de expensas: </strong>{" "}
                    {formatNumber(property.precioexpensas)}
                  </Col>
                )}
              </Row>
              <Row className="justify-content-left">
                <Col xs="auto">
                  <strong>Mascota: </strong>{" "}
                  {property.apto_mascotas ? "Sí" : "No"}{" "}
                </Col>
                <Col xs="auto">
                  <strong>Patio:</strong> {property.patio ? "Sí" : "No"}{" "}
                </Col>
              </Row>
              <Row className="justify-content-left">
                <Col xs="auto">
                  <strong>Balcón:</strong> {property.balcon ? "Sí" : "No"}{" "}
                </Col>
                <Col xs="auto">
                  <strong>Temporal:</strong> {property.temporal ? "Sí" : "No"}{" "}
                </Col>
              </Row>
              <Row className="justify-content-left">
                <Col xs="auto">
                  <strong>Requisitos:</strong>{" "}
                  {property.requisitos ? "Sí" : "No"}{" "}
                </Col>
              </Row>
              <Row className="justify-content-left">
                <Col xs="auto">
                  <strong>Recibo de sueldo:</strong>{" "}
                  {property.recibosueldo ? "Sí" : "No"}{" "}
                </Col>
                <Col xs="auto">
                  <strong>Garantia de propietario:</strong>{" "}
                  {property.garantia ? "Sí" : "No"}{" "}
                </Col>
                <Col xs="auto">
                  <strong>Seguro de caución:</strong>{" "}
                  {property.seguro ? "Sí" : "No"}{" "}
                </Col>
              </Row>
              <Col xs="auto">
                <div
                  onMouseOver={() => setShowOverlay(true)}
                  onMouseOut={() => setShowOverlay(false)}
                  className="d-inline-block position-relative"
                >
                  <FaInfoCircle style={{ marginRight: "5px" }}></FaInfoCircle>
                  <strong>Destacado:</strong> {property.destacado ? "Sí" : "No"}
                  <Overlay
                    target={document.querySelector(".position-relative")}
                    show={showOverlay}
                    placement="bottom"
                  >
                    {(props) => (
                      <Tooltip
                        title="Para destacar un inmueble contacte con el
                        administrador."
                      ></Tooltip>
                    )}
                  </Overlay>
                </div>
              </Col>
            </Card.Body>
            <Card.Footer style={{ borderColor: "white" }}>
              <Row>
                <Col xs={2}>
                  <Tooltip title="Destacar ">
                    <Button
                      variant="success"
                      style={{
                        fontSize: "20px",
                        marginBottom: "0px",
                      }}
                      className=""
                      onClick={() =>
                        handleEnviarWhatsapp(property.titulo, property.id)
                      }
                    >
                      <FaStar></FaStar>
                    </Button>
                  </Tooltip>
                </Col>
                <Col xs={2}>
                  <Tooltip title="Editar">
                    <Button
                      variant="warning"
                      style={{
                        color: "white",
                        fontSize: "20px",
                        marginBottom: "0px",
                      }}
                      className=""
                      onClick={() =>
                        handleModalOpen(property, property.imagesurl)
                      }
                    >
                      <FaEdit></FaEdit>
                    </Button>
                  </Tooltip>
                </Col>

                <Col xs={2}>
                  <Tooltip title="Eliminar">
                    <Button
                      variant="danger"
                      style={{
                        fontSize: "20px",
                        marginBottom: "0px",
                      }}
                      className=""
                      onClick={() => handleDeleteProperty(property.id)}
                    >
                      <FaRegTimesCircle></FaRegTimesCircle>
                    </Button>
                  </Tooltip>
                </Col>

                <Col xs={2}>
                  <Tooltip title={property.publicado ? "Ocultar" : "Publicar"}>
                    <Button
                      variant="secondary"
                      style={{
                        fontSize: "20px",
                        marginBottom: "0px",
                        borderRadius: "0px !important",
                      }}
                      className=""
                      onClick={() =>
                        changeVisibility(!property.publicado, property.id)
                      }
                    >
                      <FaRegEye></FaRegEye>
                    </Button>
                  </Tooltip>
                </Col>
                <Col xs={2}>
                  <Tooltip title="Compartir">
                    <Button
                      variant="info"
                      style={{
                        fontSize: "20px",
                        marginBottom: "0px",
                        borderRadius: "0px !important",
                        color: "white",
                      }}
                      className=""
                      onClick={() => copyLinkToClipboard(property.id)}
                    >
                      <FaShare></FaShare>
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        ))}
      </div>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        size="xl"
        translate="no"
        style={{ zIndex: 1050, overflow: "auto" }}
      >
        <Modal.Header
          style={{ backgroundColor: "#202529", border: "none", color: "white" }}
        >
          <Modal.Title>
            {isEditing ? "Modificar Inmueble" : "Agregar Inmueble"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: "#202529", border: "none", color: "white" }}
        >
          <Row>
            <Col md={6}>
              <Col md={12}>
                <Form.Label>
                  <strong>Fotos · {totalPhotos} /15</strong> - Puedes agregar un
                  máximo de 15 fotos.
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div>
                  <div
                    className={`image-drop-area ${
                      isDraggingOver ? "drag-over" : ""
                    }`}
                    style={{ maxHeight: 300, overflowY: "auto" }}
                    onDrop={handleImageDrop}
                    onDragOver={(event) => event.preventDefault()}
                    onDragEnter={() => setIsDraggingOver(true)}
                    onDragLeave={() => setIsDraggingOver(false)}
                    onClick={(e) => {
                      if (e.currentTarget === e.target) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    <div
                      className="image-grid"
                      onClick={(e) => {
                        if (e.currentTarget === e.target) {
                          fileInputRef.current.click();
                        }
                      }}
                    >
                      {imagePreviews.map((previewUrl, index) => (
                        <div
                          key={index}
                          className={`image-grid-item ${
                            selectedImageIndex !== null &&
                            selectedImageIndex !== index
                              ? "shake"
                              : ""
                          } ${selectedImageIndex === index ? "selected" : ""}`}
                          onClick={() => handleImageClick(index)}
                          draggable={true}
                          onDragStart={(event) => handleDragStart(event, index)}
                          onDrop={(event) => handleDrop(event, index)}
                          onDragOver={handleDragOver}
                          style={{ position: "relative" }}
                        >
                          <img
                            src={previewUrl}
                            alt={`Preview ${index}`}
                            className="image-preview"
                          />
                          {showCircle && (
                            <div className="circle">{index + 1}</div>
                          )}
                          {selectedImageIndex === index && (
                            <div className="icon-wrapper">
                              <FaTrashAlt
                                className="icontrash"
                                onClick={() => handleDeleteImage(index)}
                              />
                              <FaEye
                                className="iconview"
                                onClick={() => handleViewImage(index)}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleAddImages}
                    />
                    {imagePreviews.length < 15 && (
                      <p
                        className="mt-2 text-center"
                        onClick={(e) => {
                          if (e.currentTarget === e.target) {
                            fileInputRef.current.click();
                          }
                        }}
                      >
                        <span className="fw-bold">
                          <FaImages className="me-2" /> Agregar fotos
                        </span>
                        <br /> o arrástralas y suéltalas
                      </p>
                    )}
                  </div>
                  {showCarousel && (
                    <ImageCarousel
                      imagePreviews={imagePreviews}
                      selectedImageIndex={initialCarouselIndex}
                      handleCloseCarousel={handleCloseCarousel}
                    />
                  )}
                </div>
              </Col>

              <Row className="d-flex justify-content-center align-items-center">
                <Col className="d-flex justify-content-center">
                  <Form.Group controlId="alquiler">
                    <Form.Check
                      type="checkbox"
                      label="Alquiler"
                      checked={propertyData.alquiler}
                      onChange={handleAlquilerChange}
                      className="custom-checkbox"
                      style={{ fontWeight: "bold" }}
                    />
                  </Form.Group>
                </Col>
                <Col className="d-flex justify-content-center">
                  <Form.Group controlId="venta">
                    <Form.Check
                      type="checkbox"
                      label="Venta"
                      checked={propertyData.venta}
                      onChange={handleVentaChange}
                      className="custom-checkbox"
                      style={{ fontWeight: "bold" }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <p style={{ marginTop: 10, fontSize: 14 }}>
                Título
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group controlId="titulo" className="custom-input-group">
                <Form.Control
                  type="text"
                  placeholder="Departamento en alquiler y venta"
                  value={propertyData.titulo}
                  onChange={(e) =>
                    setPropertyData({ ...propertyData, titulo: e.target.value })
                  }
                />
              </Form.Group>
              <p style={{ marginTop: 10, fontSize: 14 }}>
                Descripcion
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group
                controlId="descripcion"
                className="custom-input-group"
              >
                <Form.Control
                  type="text"
                  as="textarea"
                  placeholder="Amplio, con buenas vistas, en buena ubicación"
                  rows={3}
                  value={propertyData.descripcion}
                  onChange={(e) =>
                    setPropertyData({
                      ...propertyData,
                      descripcion: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {propertyData.alquiler && (
                <>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    Temporalidad
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Form.Group
                    controlId="temporal"
                    className="custom-input-group"
                  >
                    <Form.Control
                      as="select"
                      value={propertyData.temporal}
                      onChange={(e) =>
                        setPropertyData({
                          ...propertyData,
                          temporal: e.target.value === "true",
                        })
                      }
                    >
                      <option
                        value=""
                        style={{
                          color: "black",
                        }}
                      >
                        Seleccione una temporalidad
                      </option>
                      <option
                        value="true"
                        style={{
                          color: "black",
                        }}
                      >
                        Sí
                      </option>
                      <option
                        value="false"
                        style={{
                          color: "black",
                        }}
                      >
                        No
                      </option>
                    </Form.Control>
                  </Form.Group>
                </>
              )}
              <p style={{ marginTop: 10, fontSize: 14 }}>
                Tipo
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group controlId="tipo" className="custom-input-group">
                <Form.Control
                  as="select"
                  value={propertyData.tipo}
                  onChange={(e) =>
                    setPropertyData({ ...propertyData, tipo: e.target.value })
                  }
                >
                  {propertyData.temporal === true
                    ? propertyTypeOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          style={{
                            color: "black",
                          }}
                        >
                          {option.label}
                        </option>
                      ))
                    : propertyTypeOptions2.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          style={{
                            color: "black",
                          }}
                        >
                          {option.label}
                        </option>
                      ))}
                </Form.Control>
              </Form.Group>
              <p style={{ marginTop: 10, fontSize: 14 }}>
                Localidad
                <span style={{ color: "red" }}>*</span>
              </p>
              {isEditing && (
                <p
                  style={{
                    marginTop: 10,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {propertyData.localidad} (Si desea modificarla cambie en el
                  campo de abajo)
                </p>
              )}
              <Form.Group controlId="localidad" className="custom-input-group">
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadOptions}
                  onChange={handleDropdownSelect3}
                  defaultOptions
                  value={propertyTypeOptions3.find(
                    (option) => option.value == propertyData.localidad
                  )}
                  placeholder={"Localidad"}
                  classNamePrefix="custom-select"
                  className="mx-auto"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#fcd088",
                    },
                  })}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      color: "white",
                      borderRadius: "0.25rem",
                      boxShadow: "none",
                      fontWeight: "bold",
                      backgroundColor: "transparent",
                      border: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: 300,
                      overflowY: "auto",
                      backgroundColor: "white",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#fcd088"
                        : "transparent",
                      color: state.isSelected ? "white" : "black",
                      fontWeight: state.isSelected ? "bold" : "normal",
                      "&:hover": {
                        backgroundColor: "#fcd088",
                        color: "white",
                        fontWeight: "bold",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "white",
                      fontWeight: "bold",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "white", // Cambia el color del texto cuando se escribe
                      fontWeight: "bold",
                    }),
                  }}
                />
              </Form.Group>

              <p style={{ marginTop: 10, fontSize: 14 }}>
                Telefono de contacto ( sin +54 9 )
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group controlId="telefono" className="custom-input-group">
                <Form.Control
                  type="text"
                  value={propertyData.telefono}
                  onChange={(e) =>
                    setPropertyData({
                      ...propertyData,
                      telefono: e.target.value,
                    })
                  }
                  placeholder="221459839"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <p style={{ marginTop: 10, fontSize: 14 }}>
                Dirección a mostrar
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group className="custom-input-group" controlId="direccion">
                <Form.Control
                  type="text"
                  placeholder="Calle X entre X y X"
                  value={propertyData.direccion}
                  onChange={(e) =>
                    setPropertyData({
                      ...propertyData,
                      direccion: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <p style={{ marginTop: 10, fontSize: 14 }}>
                Dirección exacta (Ingrese la dirección exacta de la propiedad
                para su ubicación en el mapa, es oculta al público)
                <span style={{ color: "red" }}>*</span>
              </p>
              <APIProvider
                apiKey={API_KEY}
                solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
              >
                <Map
                  mapId={"bf51a910020fa25a"}
                  defaultZoom={13}
                  defaultCenter={{ lat: -35.444523, lng: -60.884606 }}
                  gestureHandling={"greedy"}
                  disableDefaultUI={true}
                  style={{ height: 200 }}
                  center={
                    new google.maps.LatLng(
                      propertyData.localidad_maps.latitude,
                      propertyData.localidad_maps.longitude
                    )
                  }
                >
                  <AdvancedMarker
                    ref={markerRef}
                    position={
                      propertyData.localidad_maps.latitude &&
                      propertyData.localidad_maps.longitude
                        ? new google.maps.LatLng(
                            propertyData.localidad_maps.latitude,
                            propertyData.localidad_maps.longitude
                          )
                        : null
                    }
                  />
                </Map>

                <MapControl position={ControlPosition.TOP_LEFT}>
                  <div className="autocomplete-control">
                    <PlaceAutocomplete
                      onPlaceSelect={(place) => {
                        setSelectedPlace(place);

                        // Extraer coordenadas del lugar seleccionado
                        const latLng = {
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        };

                        // Actualizar el estado con las nuevas coordenadas
                        setPropertyData({
                          ...propertyData,
                          localidad_maps: {
                            latitude: latLng.lat,
                            longitude: latLng.lng,
                          },
                        });
                      }}
                    />
                  </div>
                </MapControl>
              </APIProvider>
              <p style={{ marginTop: 10, fontSize: 14 }}>
                Superficie Total (solo número sin nada agregado)
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group className="custom-input-group" controlId="area">
                <Form.Control
                  type="number"
                  placeholder="50"
                  value={propertyData.area}
                  onChange={(e) =>
                    setPropertyData({ ...propertyData, area: e.target.value })
                  }
                />
              </Form.Group>

              <p style={{ marginTop: 10, fontSize: 14 }}>
                Dormitorios (solo número)
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group
                className="custom-input-group"
                controlId="dormitorios"
              >
                <Form.Control
                  type="number"
                  placeholder="1"
                  value={propertyData.dormitorios}
                  onChange={(e) =>
                    setPropertyData({
                      ...propertyData,
                      dormitorios: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <p style={{ marginTop: 10, fontSize: 14 }}>
                Baños (solo número)
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Group className="custom-input-group" controlId="banios">
                <Form.Control
                  type="number"
                  placeholder="1"
                  value={propertyData.banios}
                  onChange={(e) =>
                    setPropertyData({ ...propertyData, banios: e.target.value })
                  }
                />
              </Form.Group>
              <p
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                <FaInfoCircle style={{ marginRight: 10, fontSize: 10 }} />
                Para no mostrar el precio, ingresa 0 en los campos de venta o
                alquiler, y los interesados podrán contactarte para consultar.
              </p>
              {propertyData.alquiler && (
                <>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    Precio de alquiler (sin puntos ni comas)
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Form.Group className="custom-input-group" controlId="precio">
                    <Form.Control
                      type="number"
                      placeholder="100000"
                      value={propertyData.precio}
                      onChange={(e) =>
                        setPropertyData({
                          ...propertyData,
                          precio: e.target.value == "" ? null : e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </>
              )}

              {propertyData.venta && (
                <>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    Precio de venta (sin puntos ni comas)
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Form.Group className="custom-input-group" controlId="precio">
                    <Form.Control
                      type="number"
                      placeholder="100000"
                      value={propertyData.precioventa}
                      onChange={(e) =>
                        setPropertyData({
                          ...propertyData,
                          precioventa:
                            e.target.value == "" ? null : e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </>
              )}

              {propertyData.alquiler && (
                <>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    Moneda Alquiler
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Form.Group controlId="moneda" className="custom-input-group">
                    <Form.Control
                      as="select"
                      value={propertyData.moneda}
                      onChange={(e) =>
                        setPropertyData({
                          ...propertyData,
                          moneda: e.target.value == "" ? null : e.target.value,
                        })
                      }
                    >
                      {propertyTypeMonedas.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          style={{
                            color: "black",
                          }}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </>
              )}

              {propertyData.venta && (
                <>
                  <p style={{ marginTop: 10, fontSize: 14 }}>
                    Moneda Venta
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <Form.Group controlId="moneda" className="custom-input-group">
                    <Form.Control
                      as="select"
                      value={propertyData.monedaventa}
                      onChange={(e) =>
                        setPropertyData({
                          ...propertyData,
                          monedaventa:
                            e.target.value == "" ? null : e.target.value,
                        })
                      }
                    >
                      {propertyTypeMonedas.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          style={{
                            color: "black",
                          }}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </>
              )}

              <p style={{ marginTop: 10, fontSize: 14 }}>
                Adicionales
                <span style={{ color: "red" }}>*</span>
              </p>
              <Col className="mt-1">
                <Form.Group controlId="cochera">
                  <Form.Check
                    type="checkbox"
                    label="Cochera"
                    checked={propertyData.cochera}
                    onChange={handleCocheraChange}
                    className="custom-checkbox"
                  />
                </Form.Group>
                {showGarageInput && (
                  <>
                    <p style={{ marginTop: 10, fontSize: 14 }}>
                      Cantidad de cocheras
                    </p>
                    <Form.Group
                      className="custom-input-group"
                      controlId="garages"
                      style={{ marginBottom: 10 }}
                    >
                      <Form.Control
                        type="number"
                        value={garageCount}
                        onChange={(e) => {
                          const newCount = parseInt(e.target.value);

                          setGarageCount(newCount);
                        }}
                      />
                    </Form.Group>
                  </>
                )}

                {propertyData.alquiler && (
                  <Form.Group controlId="expensas">
                    <Form.Check
                      type="checkbox"
                      label="Expensas"
                      checked={propertyData.expensas}
                      onChange={handleExpensasChange}
                      className="custom-checkbox"
                    />
                  </Form.Group>
                )}
                {showExpensasInput && (
                  <>
                    <p style={{ marginTop: 10, fontSize: 14 }}>
                      Precio de expensas * (sin puntos ni comas)
                    </p>
                    <Form.Group
                      className="custom-input-group"
                      controlId="precioexpensas"
                      style={{ marginBottom: 10 }}
                    >
                      <Form.Control
                        type="text"
                        value={propertyData.precioexpensas}
                        onChange={(e) =>
                          setPropertyData({
                            ...propertyData,
                            precioexpensas: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </>
                )}

                <Form.Group controlId="aptoMascotas">
                  <Form.Check
                    type="checkbox"
                    label="Apto mascotas"
                    checked={propertyData.apto_mascotas}
                    onChange={(e) =>
                      setPropertyData({
                        ...propertyData,
                        apto_mascotas: e.target.checked,
                      })
                    }
                    className="custom-checkbox"
                  />
                </Form.Group>
                <Form.Group controlId="patio">
                  <Form.Check
                    type="checkbox"
                    label="Patio"
                    checked={propertyData.patio}
                    onChange={(e) =>
                      setPropertyData({
                        ...propertyData,
                        patio: e.target.checked,
                      })
                    }
                    className="custom-checkbox"
                  />
                </Form.Group>

                <Form.Group controlId="balcon">
                  <Form.Check
                    type="checkbox"
                    label="Balcón"
                    checked={propertyData.balcon}
                    onChange={(e) =>
                      setPropertyData({
                        ...propertyData,
                        balcon: e.target.checked,
                      })
                    }
                    className="custom-checkbox"
                  />
                </Form.Group>

                <Form.Group controlId="requisitos">
                  <Form.Check
                    type="checkbox"
                    label="Requisitos"
                    checked={propertyData.requisitos || showRequisitos}
                    onChange={handleRequisitosChange}
                    className="custom-checkbox"
                  />
                  <p> </p>
                </Form.Group>

                {showRequisitos && (
                  <Container>
                    <Form.Group controlId="recibosueldo">
                      <Form.Check
                        type="checkbox"
                        label="Recibo de sueldo"
                        checked={propertyData.recibosueldo}
                        onChange={(e) =>
                          handleCheckboxChange("recibosueldo", e.target.checked)
                        }
                        className="custom-checkbox"
                      />
                      <p>{propertyReq.recibosueldo ? "(Excluyente)" : ""}</p>
                    </Form.Group>

                    <Form.Group controlId="garantia">
                      <Form.Check
                        type="checkbox"
                        label="Garantía de propietario"
                        checked={propertyData.garantia}
                        onChange={(e) =>
                          handleCheckboxChange("garantia", e.target.checked)
                        }
                        className="custom-checkbox"
                      />
                      <p>{propertyReq.garantia ? "(Excluyente)" : ""}</p>
                    </Form.Group>

                    <Form.Group controlId="seguro">
                      <Form.Check
                        type="checkbox"
                        label="Seguro de caución"
                        checked={propertyData.seguro}
                        onChange={(e) =>
                          handleCheckboxChange("seguro", e.target.checked)
                        }
                        className="custom-checkbox"
                      />
                      <p>{propertyReq.seguro ? "(Excluyente)" : ""}</p>
                    </Form.Group>
                  </Container>
                )}
              </Col>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer
          style={{ backgroundColor: "#202529", border: "none", color: "white" }}
        >
          {loadingInmueble ? (
            <div className="d-flex justify-content-start mx-auto">
              <div className="loader d-inline-block"></div>
              <h6 className="d-inline-block" style={{ marginLeft: "40px" }}>
                Aguarde mientras se cargan los archivos <br /> Imagenes (
                {valorCargaImagen} %)
              </h6>
            </div>
          ) : (
            <div></div>
          )}

          {editInmueble ? (
            <div className="d-flex justify-content-start mx-auto">
              <div className="loader d-inline-block"></div>
              <h6 className="d-inline-block" style={{ marginLeft: "40px" }}>
                Aguarde mientras se edita el inmueble
              </h6>
            </div>
          ) : (
            <div></div>
          )}
          {showError && (
            <Alert variant="danger">
              Por favor completa todos los campos obligatorios y seleccione una
              opcion: Venta o Alquiler.
            </Alert>
          )}
          <a onClick={handleModalClose} className="inicio">
            <FaRegTimesCircle style={{ marginRight: 10 }}></FaRegTimesCircle>
            Cancelar
          </a>
          {isEditing ? (
            <a onClick={handleSaveProperty} className="inicio">
              <FaEdit style={{ marginRight: 10 }}></FaEdit>Confirmar edición
            </a>
          ) : (
            <a
              onClick={botonDeshabilitado ? null : handleSaveProperty}
              className="inicio"
              style={{
                pointerEvents: botonDeshabilitado ? "none" : "auto",
                opacity: botonDeshabilitado ? 0.5 : 1,
                cursor: botonDeshabilitado ? "not-allowed" : "pointer",
              }}
            >
              <FaPlusCircle style={{ marginRight: 10 }} />
              Agregar
            </a>
          )}
        </Modal.Footer>
      </Modal>

      {!isLoading && !showTourOnLogin && (
        <Modal show={showModal2} onHide={() => setShowModal2(false)}>
          <Modal.Header
            style={{
              backgroundColor: "#202529",
              border: "none",
              color: "white",
              textAlign: "center",
            }}
          >
            <Modal.Title>Bienvenido a InmoS!</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: "#202529",
              border: "none",
              color: "white",
            }}
          >
            ¿Quieres ver la visita guiada?
            <Form.Check
              type="checkbox"
              label="No mostrar este mensaje nuevamente"
              onChange={(e) => setDontShowAgain(e.target.checked)}
              style={{ marginTop: 10 }}
            />
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: "#202529",
              border: "none",
              color: "white",
            }}
          >
            <a
              className="inicio2"
              onClick={() => {
                if (dontShowAgain) {
                  localStorage.setItem("showTourOnLogin", true);
                }
                setShowModal2(false);
              }}
            >
              No, gracias
            </a>
            <a
              className="inicio2"
              onClick={() => {
                setShowModal2(false);
                handleStartTour();
              }}
            >
              Si, muestrame
            </a>
          </Modal.Footer>
        </Modal>
      )}

      {/* Modal para mostrar los formatos permitidos */}
      {/* <Modal show={isModalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Formatos Permitidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Aquí puedes mostrar los formatos permitidos, por ejemplo: */}
      {/* <ul>
            {formatosValidos.map((formato, index) => (
              <li key={index}>{formato.toString()}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer> */}
      {/* </Modal>  */}
    </Container>
  );
};

const ImageCarousel = ({
  imagePreviews,
  selectedImageIndex,
  handleCloseCarousel,
}) => {
  return (
    <div className="image-carousel-container">
      <ResponsiveCarousel
        showThumbs={false}
        showStatus={false}
        selectedItem={selectedImageIndex}
      >
        {imagePreviews.map((previewUrl, index) => (
          <div key={index} className="carousel-image-container">
            <img
              src={previewUrl}
              alt={`Preview ${index}`}
              className="carousel-image"
            />
          </div>
        ))}
      </ResponsiveCarousel>
      <div className="close-button" onClick={handleCloseCarousel}>
        X
      </div>
    </div>
  );
};

const handleDragOver = (event) => {
  event.preventDefault();
};

export default InmueblesComponent;

const PlaceAutocomplete = ({ onPlaceSelect }) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);
  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);
  return (
    <div className="autocomplete-container">
      <input ref={inputRef} />
    </div>
  );
};

const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }

    marker.position = place.geometry?.location;
  }, [map, place, marker]);
  return null;
};
