import { useState, useEffect } from "react";
import MapComponentIndividual from "../../assets/components/HomePage/Mapa/MapaIndividual";
import {
  Container,
  Card,
  Carousel,
  Button,
  Row,
  Col,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaWhatsapp,
  FaArrowLeft,
  FaVideo,
  FaEnvelopeOpenText,
  FaExpand,
  FaTimes,
  FaPaperPlane,
  FaHome,
  FaMapPin,
  FaBed,
  FaBath,
  FaCar,
  FaTree,
  FaLeaf,
  FaClock,
  FaDog,
  FaFile,
  FaClipboardList,
  FaClipboard,
  FaClipboardCheck,
  FaMoneyBill,
  FaLink,
} from "react-icons/fa";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./inmueble.css";
import NavBar from "../../assets/components/HomePage/NavBar/NavBar";
import config from "./../../../config.json";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import imagen from "../../assets/images/no_result_inmueble.png";
const apiUrl = config.apiUrl;
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "react-responsive";

const Inmueble = () => {
  const { id } = useParams();

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleMaximizeClick = () => {
    setIsFullScreen(!isFullScreen); // Cambia el estado de pantalla completa
  };
  const navigate = useNavigate();

  const handleVolver = () => {
    navigate(`/busqueda`);
  };

  const showSuccessAlert = (message) => {
    Swal.fire({
      icon: "success",
      title: "¡Correo enviado correctamente!",
      text: "El propietario se contará contigo lo antes posible",
      timer: 5000,
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const formatNumber = (number) => {
    return number.toLocaleString("es-ES");
  };

  const [showModal, setShowModal] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault(); // Esto evita la recarga de la página

    const formData = new FormData();
    formData.append("nombre", nombre);
    formData.append("correo", email);
    formData.append("telefono", telefono);
    formData.append("mensaje", mensaje);

    try {
      axios.post(apiUrl + `/api/inmuebles/comunicarcorreo/${id}`, formData, {});

      showSuccessAlert();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error al enviar correo", error);
      throw error;
    }

    setShowModal(false); // Cierra el modal después del envío
  }

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + `/api/inmuebles/${id}`);

        if (response.data.data.length === 0) {
          setError(true);
          setLoading(false);
          return;
        }

        const propertyData = response.data.data; // Solo toma el primer elemento del array
        setProperty(propertyData);
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
        setError(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleMessageWhatsApp = () => {
    // Mensaje predeterminado
    const currentUrl = window.location.href;
    const message = `¡Hola! Estoy interesado en el inmueble de ${property.direccion} \n\n ${currentUrl}`;

    // URL para abrir WhatsApp con el mensaje predefinido
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      property.telefono
    }&text=${encodeURIComponent(message)}`;

    // Redirigir a la URL de WhatsApp
    window.open(whatsappUrl, "_blank");
  };

  if (loading) {
    return (
      <Container className="d-flex flex-column align-items-center justify-content-center vh-100 ">
        <div className="loader"></div>
      </Container>
    );
  }

  if (!property) {
    return (
      <>
        <NavBar />
        <Container className="mt-5 text-center" style={{ color: "white" }}>
          <img
            src={imagen}
            alt="Sin resultados"
            style={{ maxWidth: "300px" }}
          />
          <h3>El inmueble que esta buscando no existe</h3>
          <h6 style={{ marginBottom: 30 }}>
            Verifique el codigo que ingresó o intente nuevamente
          </h6>
          <a href="/busqueda" className="inicio">
            Volver
          </a>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <NavBar />
        <Container className="mt-5 text-center" style={{ color: "white" }}>
          <h3>El inmueble que esta buscando no existe :(</h3>
          <Button
            as={Link}
            to="/busqueda"
            variant="danger"
            className="button-35"
          >
            Volver
          </Button>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{property.titulo}</title>
        <meta property="og:title" content={property.titulo} />
        <meta property="og:description" content={property.descripcion} />
        <meta
          property="og:url"
          content={`https://inmos.com.ar/inmueble/${id}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={property.imagesurl[0]} />
      </Helmet>
      <NavBar></NavBar>
      <Container className="mt-2">
        <Row>
          <Col xs={12} md={6}>
            <Carousel className={isFullScreen ? "fullscreen-carousel" : ""}>
              {property.imagesurl &&
                property.imagesurl
                  .slice()
                  .sort((a, b) => a.localeCompare(b))
                  .map((image, index) => (
                    <Carousel.Item key={index}>
                      <div className="carousel-item-container">
                        <img
                          style={{
                            objectFit: isFullScreen ? "contain" : "cover",
                            height: isFullScreen ? "600px" : "500px",
                            width: "100%",
                            filter:
                              "drop-shadow(0 0 5px rgba(244, 246, 247, 0.9))", // Ajuste del color al fondo
                            maskImage:
                              "linear-gradient(to bottom, rgba(244, 246, 247, 0.9) 95%, transparent 100%)", // Gradiente para una mejor transición
                            borderRadius: "6px",
                          }}
                          src={image}
                          alt={`Imagen ${index + 1}`}
                        />

                        {isFullScreen ? (
                          <FaTimes
                            className="fullscreen-icon icon-background"
                            title="Cerrar"
                            onClick={handleMaximizeClick}
                          />
                        ) : (
                          <FaExpand
                            className="expand-icon icon-background"
                            title="Maximizar"
                            onClick={handleMaximizeClick}
                          />
                        )}
                      </div>
                    </Carousel.Item>
                  ))}
            </Carousel>

            {!isFullScreen && (
              <MapComponentIndividual
                location={property.direccion}
                latitude={property.localidad_maps.latitude}
                longitude={property.localidad_maps.longitude}
                localidad={property.localidad}
              ></MapComponentIndividual>
            )}
          </Col>
          <Col xs={12} md={6} style={{ marginTop: isMobile ? 10 : 0 }}>
            <Card
              style={{
                paddingLeft: "0.5rem",
                color: "white",
                backgroundColor: "transparent",
                border: "0.5px solid #fcd088",
              }}
            >
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>
                  <Row className="pb-4 justify-content-center">
                    <Col md={12} className="d-flex justify-content-center">
                      <a
                        className="inicio"
                        onClick={() => {
                          handleVolver();
                        }}
                        style={{ width: "100%" }}
                      >
                        <FaArrowLeft style={{ marginRight: 10 }} />
                        ATRÁS
                      </a>
                    </Col>
                  </Row>
                </Card.Title>
                <Card.Title style={{ textAlign: "center" }}>
                  <Row className=" justify-content-center">
                    <Col md={12} className="d-flex justify-content-center">
                      <p>{property.titulo}</p>
                    </Col>
                  </Row>
                </Card.Title>

                <Card.Text>
                  <Row className="pt-1">
                    {property.alquiler && (
                      <>
                        <Row>
                          <Col className="d-flex justify-content-center align-items-center">
                            <p>PRECIO ALQUILER</p>
                          </Col>
                          <Col className="d-flex justify-content-center align-items-center">
                            <p>EXPENSAS</p>
                          </Col>
                        </Row>

                        <Col className="d-flex justify-content-center align-items-center">
                          {property.moneda === "ARS" ? (
                            <span
                              className="fw-bold"
                              style={{
                                cursor: "default",
                                fontSize: "25px",
                                color: "#fcd088",
                              }}
                            >
                              ${" "}
                              {property.precio == 0
                                ? "Consultar"
                                : property.precio &&
                                  formatNumber(property.precio)}
                            </span>
                          ) : (
                            <span
                              className="fw-bold"
                              style={{
                                cursor: "default",
                                fontSize: "25px",
                                color: "#fcd088",
                              }}
                            >
                              U$D{" "}
                              {property.precio && formatNumber(property.precio)}
                            </span>
                          )}
                        </Col>

                        <Col className="d-flex justify-content-center align-items-center">
                          <Row>
                            {property.precioexpensas ? (
                              <Col
                                style={{
                                  fontSize: "22px",
                                  marginRight: "30px",
                                  color: "#fcd088",
                                  fontWeight: "bold",
                                }}
                              >
                                $ {formatNumber(property.precioexpensas)}
                              </Col>
                            ) : (
                              <Col
                                style={{
                                  fontSize: "22px",
                                  marginRight: "30px",
                                  color: "#fcd088",
                                  fontWeight: "bold",
                                }}
                              >
                                No tiene
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row className="pt-1">
                    {property.venta && (
                      <>
                        <Row>
                          <Col className="d-flex justify-content-center align-items-center">
                            <p>PRECIO VENTA</p>
                          </Col>
                        </Row>

                        <Row className="pt-1">
                          <Col className="d-flex justify-content-center align-items-center">
                            {property.monedaventa === "ARS" ? (
                              <span
                                className="fw-bold"
                                style={{
                                  cursor: "default",
                                  fontSize: "25px",
                                  color: "#fcd088",
                                }}
                              >
                                ${" "}
                                {property.precioventa == 0
                                  ? "Consultar"
                                  : property.precioventa &&
                                    formatNumber(property.precioventa)}
                              </span>
                            ) : (
                              <span
                                className="fw-bold"
                                style={{
                                  cursor: "default",
                                  fontSize: "25px",
                                  color: "#fcd088",
                                }}
                              >
                                U$D{" "}
                                {property.precioventa == 0
                                  ? "Consultar"
                                  : property.precioventa &&
                                    formatNumber(property.precioventa)}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </Row>

                  <Row className="pb-4 justify-content-center">
                    <Col md={12} className="d-flex justify-content-center">
                      <a
                        className="inicio"
                        style={{
                          textAlign: "center",
                          width: "100%",
                        }}
                        onClick={() => {
                          handleMessageWhatsApp();
                        }}
                      >
                        <FaWhatsapp style={{ marginRight: 10 }} />
                        Whatsapp
                      </a>
                    </Col>

                    <Col md={12} className="d-flex justify-content-center">
                      <a
                        className="inicio"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 10,
                        }}
                        onClick={() => setShowModal(true)}
                      >
                        <FaEnvelopeOpenText style={{ marginRight: 10 }} />
                        Correo
                      </a>
                    </Col>
                    <Col md={12} className="d-flex justify-content-center">
                      <a
                        className="inicio"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: 10,
                        }}
                        onClick={() => {
                          if (property.user.tipo == "Particular") {
                            navigate(
                              `/inmobiliaria/${property.user.id}/${property.user.apellido}${property.user.nombre}`
                            );
                          } else {
                            navigate(
                              `/inmobiliaria/${property.user.id}/${property.user.razonsocial}`
                            );
                          }
                        }}
                      >
                        <FaLink style={{ marginRight: 10 }} />
                        Agente Inmobiliario
                      </a>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <span
                        className="fw-bold pt-2"
                        style={{ marginTop: "5px", fontSize: "14px" }}
                      >
                        Información del inmueble
                      </span>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                        }}
                      >
                        <Col className="d-flex align-items-center">
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">
                                Tipo de inmueble
                              </Tooltip>
                            }
                          >
                            <span>
                              <FaHome
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.tipo}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>

                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Dirección</Tooltip>
                            }
                          >
                            <span>
                              <FaMapPin
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.direccion}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">
                                Superficie
                              </Tooltip>
                            }
                          >
                            <span>
                              <FaExpand
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.area} m²
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">
                                Dormitorios
                              </Tooltip>
                            }
                          >
                            <span>
                              <FaBed
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.dormitorios}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Baños</Tooltip>
                            }
                          >
                            <span>
                              <FaBath
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.banios}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Cocheras</Tooltip>
                            }
                          >
                            <span>
                              <FaCar
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.cochera ? "Sí" : "No"}
                              {property.cochera && ` (${property.cantgaraje})`}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>

                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Patio</Tooltip>
                            }
                          >
                            <span>
                              <FaTree
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.patio ? "Sí" : "No"}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Balcón</Tooltip>
                            }
                          >
                            <span>
                              <FaLeaf
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.balcon ? "Sí" : "No"}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Temporal</Tooltip>
                            }
                          >
                            <span>
                              <FaClock
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.temporal ? "Sí" : "No"}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Mascotas</Tooltip>
                            }
                          >
                            <span>
                              <FaDog
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.apto_mascotas ? "Sí" : "No"}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-expensas">Expensas</Tooltip>
                            }
                          >
                            <span>
                              <FaFile
                                style={{
                                  color: "#fcd088",
                                  fontSize: 24,
                                  marginRight: 10,
                                }}
                              />
                              {property.expensas ? "Sí" : "No"}
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={6}>
                      <span
                        className="fw-bold pt-2"
                        style={{ marginTop: "5px", fontSize: "14px" }}
                      >
                        Requisitos legales
                      </span>

                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          {property.recibosueldo ? (
                            <FaClipboardCheck
                              style={{
                                color: "#fcd088",
                                fontSize: 24,
                                marginRight: 10,
                              }}
                            />
                          ) : (
                            <FaClipboard
                              style={{
                                color: "#fcd088",
                                fontSize: 24,
                                marginRight: 10,
                              }}
                            />
                          )}
                          Recibo de sueldo:{" "}
                          {property.recibosueldo ? "Sí" : "No"}{" "}
                          {property.Orecibosueldo ? "(Excluyente)" : ""}
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          {property.garantia ? (
                            <FaClipboardCheck
                              style={{
                                color: "#fcd088",
                                fontSize: 24,
                                marginRight: 10,
                              }}
                            />
                          ) : (
                            <FaClipboard
                              style={{
                                color: "#fcd088",
                                fontSize: 24,
                                marginRight: 10,
                              }}
                            />
                          )}
                          Garantia de propietario:{" "}
                          {property.garantia ? "Sí" : "No"}{" "}
                          {property.Ogarantiapropietario ? "(Excluyente)" : ""}
                        </Col>
                      </Row>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          {property.seguro ? (
                            <FaClipboardCheck
                              style={{
                                color: "#fcd088",
                                fontSize: 24,
                                marginRight: 10,
                              }}
                            />
                          ) : (
                            <FaClipboard
                              style={{
                                color: "#fcd088",
                                fontSize: 24,
                                marginRight: 10,
                              }}
                            />
                          )}
                          Seguro de caución: {property.seguro ? "Sí" : "No"}{" "}
                          {property.Osegurocaucion ? "(Excluyente)" : ""}
                        </Col>
                      </Row>
                      <span
                        className="fw-bold pt-2"
                        style={{ marginTop: "5px", fontSize: "14px" }}
                      >
                        Moneda
                      </span>
                      <Row
                        className="text-start"
                        style={{
                          marginBottom: 5,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                      >
                        <Col>
                          <FaMoneyBill
                            style={{
                              color: "#fcd088",
                              fontSize: 24,
                              marginRight: 10,
                            }}
                          />{" "}
                          {property.moneda}
                        </Col>
                      </Row>
                      <Col>
                        <span
                          className="fw-bold pt-2"
                          style={{ marginTop: "5px", fontSize: "14px" }}
                        >
                          Detalle
                        </span>
                      </Col>
                      <Row className="">
                        <Col>{property.descripcion}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                  <Modal.Header
                    style={{
                      backgroundColor: "#202529",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <Modal.Title>Enviar correo al anunciante</Modal.Title>
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      backgroundColor: "#202529",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <Form>
                      <Form.Group
                        className="custom-input-group mb-3"
                        controlId="formBasicName"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Ingrese su nombre"
                          required
                          value={nombre}
                          onChange={(e) => setNombre(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="custom-input-group mb-3"
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          type="email"
                          placeholder="Dirección de correo electronico"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="custom-input-group mb-3"
                        controlId="formBasicPhone"
                      >
                        <Form.Control
                          type="number"
                          maxLength={11}
                          placeholder="Número de teléfono"
                          pattern="[0-9]*"
                          title="Solo se permiten números"
                          required
                          value={telefono}
                          onChange={(e) => setTelefono(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="custom-input-group mb-3"
                        controlId="formBasicMessage"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Ingrese su mensaje"
                          required
                          value={mensaje}
                          onChange={(e) => setMensaje(e.target.value)}
                        />{" "}
                      </Form.Group>

                      <a
                        type="submit"
                        className="inicio"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        <FaPaperPlane style={{ marginRight: 10 }} />
                        Enviar
                      </a>
                    </Form>
                  </Modal.Body>
                </Modal>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Inmueble;
