/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import PropertyCard from "../../assets/components/FiltradoPage/CartaBusqueda/CartaBusqueda";
import RangeSlider from "react-bootstrap-range-slider";

import {
  Form,
  Row,
  Col,
  Button,
  Container,
  Modal,
  ButtonGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaFilter, FaBroom } from "react-icons/fa";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./filtrado.css";
import NavBar from "../../assets/components/HomePage/NavBar/NavBar";
import config from "./../../../config.json";
import imagen from "../../../public/images/no_result_inmueble.png";
import AsyncSelect from "react-select/async";
import * as Sentry from "@sentry/react";

const apiUrl = config.apiUrl;

const Filtrado = () => {
  const [properties, setProperties] = useState([]);
  const [filter, setFilter] = useState({
    tipo: "",
    dormitorios: "",
    localidad: "",
    precio: "",
    area: "",
    cochera: "",
    expensas: "",
    apto_mascotas: "",
    patio: "",
    balcon: "",
    banios: "",
    moneda: "",
    tipousuario: "",
    razonsocial: "",
  });
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isDormitoriosActive, setIsDormitoriosActive] = useState(
    filter.dormitorios === ""
  );

  const [propertyTypeOptions3, setPropertyTypeOptions3] = useState([]);

  const fetchLocalidades = async (inputValue = "") => {
    try {
      const response = await axios.get(
        "https://inmos.com.ar/jsons/localidades.json"
      );
      const localidades = response.data.localidades;
      const localidadesFormatted = localidades.map((localidad) => ({
        value: `${localidad.nombre}, ${localidad.provincia.nombre}`,
        label: `${localidad.nombre}, ${localidad.provincia.nombre}`,
      }));

      // Filtra las localidades según la entrada del usuario
      const filteredLocalidades = localidadesFormatted.filter((localidad) =>
        localidad.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      const uniqueLocalidades = filteredLocalidades.filter(
        (localidad, index, self) =>
          index === self.findIndex((l) => l.value === localidad.value)
      );

      setPropertyTypeOptions3(localidadesFormatted);

      return uniqueLocalidades;
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error al cargar las localidades:", error);
      return [];
    }
  };

  const loadOptions = (inputValue, callback) => {
    fetchLocalidades(inputValue).then((options) => {
      callback(options);
    });
  };

  const [isBaniosActive, setIsBaniosActive] = useState(filter.banios === "");

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const clearFilters = () => {
    setFilter({
      tipo: "",
      dormitorios: "",
      localidad: "",
      temporal: "",
      precio: "",
      area: "",
      cochera: "",
      expensas: "",
      apto_mascotas: "",
      patio: "",
      balcon: "",
      banios: "",
      moneda: "",
      tipousuario: "",
      razonsocial: "",
    });
  };

  const propertyTypeOptions = [
    { value: "Departamento", label: "Departamento" },
    { value: "Casa", label: "Casa" },
    { value: "PH", label: "PH" },
    { value: "Cochera", label: "Cochera" },
    { value: "Galpón", label: "Galpón" },
    { value: "Local comercial", label: "Local comercial" },
    { value: "Oficina", label: "Oficina" },
    { value: "Otros", label: "Otros" },
    { value: "", label: "Elegir tipo de inmueble" },
  ];

  const propertyTypeMoneda = [
    { value: "", label: "Cualquiera" },
    { value: "ARS", label: "ARS" },
    { value: "USD", label: "USD" },
  ];

  const propertyTypeCondicion = [
    { value: "", label: "Cualquiera" },
    { value: "venta", label: "Venta" },
    { value: "compra", label: "Compra" },
  ];

  const propertyTypeUser = [
    { value: "", label: "Cualquiera" },
    { value: "Inmobiliaria", label: "Inmobiliaria" },
    { value: "Particular", label: "Particular" },
  ];
  const propertyTypeOptions2 = [
    { value: "Hotel", label: "Hotel" },
    { value: "Departamento", label: "Departamento" },
    { value: "Casa", label: "Casa" },
    { value: "PH", label: "PH" },
    { value: "Quinta", label: "Quinta" },
    { value: "Cochera", label: "Cochera" },
    { value: "Hostel", label: "Hostel" },
    { value: "Pensiones/Habitaciones", label: "Pensiones/Habitaciones" },
    { value: "Otros", label: "Otros" },
    { value: "", label: "Cualquiera" },
  ];

  const location = useLocation();
  const [razonesSociales, setRazonesSociales] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const propertyType = queryParams.get("propertyType") || "";
    const localidad = queryParams.get("Localidad") || "";
    const temporal = queryParams.get("Temporal")
      ? queryParams.get("Temporal").toLowerCase() === "true"
      : false || "";

    setFilter((prevFilter) => ({
      ...prevFilter,
      tipo: propertyType !== "" ? propertyType : prevFilter.tipo,
      temporal: propertyType !== "" ? temporal : prevFilter.temporal,
      localidad: localidad !== "" ? localidad : prevFilter.localidad,
    }));

    const fetchData = async () => {
      try {
        const [response, response2] = await Promise.all([
          axios.get(apiUrl + "/api/inmuebles?populate=imagenes"),
        ]);

        setProperties(
          response.data.data.map((property) => ({
            id: property.id,
            razonsocial: property.user.razonsocial,
            ...property,
          }))
        );

        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
      }
    };

    fetchData();
  }, []);

  const fetchRazonesSociales = async (inputValue = "") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/user/inmobiliarias/rsociales`
      );

      const rs = response.data.data;

      const razonessocialesFormated = rs.map((localidad) => ({
        value: localidad.razonsocial,
        label: localidad.razonsocial,
      }));

      // Filtrar las opciones según el input
      const filteredRazonesSociales = razonessocialesFormated.filter((rso) =>
        rso.label.toLowerCase().includes(inputValue.toLowerCase())
      );

      // Eliminar duplicados
      const uniqueRazonesSociales = filteredRazonesSociales.filter(
        (rs, index, self) =>
          index === self.findIndex((r) => r.value === rs.value)
      );

      // Aquí actualizamos el estado con los valores filtrados
      setRazonesSociales(uniqueRazonesSociales);

      return uniqueRazonesSociales;
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error al cargar las razones sociales:", error);
      return [];
    }
  };

  const loadOptions2 = (inputValue, callback) => {
    fetchRazonesSociales(inputValue).then((options) => {
      callback(options);
    });
  };

  const handleDropdownSelect4 = (selectedOption) => {
    setFilter({ ...filter, razonsocial: selectedOption.value });
  };

  const handleDropdownSelect3 = (selectedOption) => {
    setFilter({ ...filter, localidad: selectedOption.value });
  };

  const handleFilterChange = (event) => {
    const name = event.target.name;
    let value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setFilter({
      ...filter,
      [name]: value,
    });
  };

  // ...
  const filteredProperties = properties.filter((property) => {
    for (let key in filter) {
      if (filter[key] !== "") {
        if (key === "precio" || key === "area") {
          if (property[key] > parseInt(filter[key])) {
            return false;
          }
        } else if (key === "dormitorios") {
          if (filter.dormitorios === "6+") {
            if (property[key] <= 5) {
              return false;
            }
          } else {
            if (property[key] !== parseInt(filter[key])) {
              return false;
            }
          }
        } else if (key === "banios") {
          if (filter.banios === "6+") {
            if (property[key] <= 5) {
              return false;
            }
          } else {
            if (property[key] !== parseInt(filter[key])) {
              return false;
            }
          }
        } else if (typeof filter[key] === "boolean") {
          if (property[key] !== filter[key]) {
            return false;
          }
        } else if (
          typeof filter[key] === "string" &&
          filter[key].toLowerCase() !== "cualquiera"
        ) {
          if (
            property[key] == null ||
            property[key].toString().toLowerCase() !== filter[key].toLowerCase()
          ) {
            return false;
          }
        }
      }
    }
    return true;
  });

  // ...

  // Filtra propiedades destacadas
  const destacadas = filteredProperties.filter(
    (property) => property.destacado
  );

  // Filtra propiedades no destacadas
  const noDestacadas = filteredProperties.filter(
    (property) => !property.destacado
  );

  // Concatena las propiedades destacadas al principio
  const propertiesToRender = destacadas.concat(noDestacadas);

  const maxPrecio = filter.moneda === "ARS" ? 3000000 : 4000;

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);

  return (
    <Container fluid>
      <NavBar></NavBar>
      <Form className="p-4" style={{ backgroundColor: "#202529" }}>
        <Container fluid>
          <Row className="d-flex justify-content-center">
            <Col
              sm={12}
              md={6}
              style={{
                marginBottom: 5,
                marginTop: 5,
              }}
            >
              <a
                className="botonmasdetalles d-block text-center"
                onClick={handleShow}
                style={{ marginTop: 10 }}
              >
                <FaFilter style={{ marginRight: 10 }} /> aplicar filtros
              </a>
            </Col>
            <Col sm={12} md={6} style={{ marginBottom: 5, marginTop: 5 }}>
              <a
                className="botonmasdetalles d-block text-center"
                onClick={clearFilters}
                style={{ marginTop: 10 }}
              >
                <FaBroom style={{ marginRight: 10 }} /> Limpiar filtros
              </a>
            </Col>
          </Row>
        </Container>
      </Form>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#202529", border: "none", color: "white" }}
        >
          <Modal.Title>Filtros</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: "#202529", border: "none", color: "white" }}
        >
          <Col sm className="custom-input-group mb-3">
            <Form.Control
              as="select"
              name="tipo"
              placeholder="Tipo de inmueble"
              value={filter.tipo}
              onChange={handleFilterChange}
              style={{
                textAlign: "center",
              }}
            >
              {filter.temporal === "true"
                ? propertyTypeOptions2.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      style={{
                        color: "black",
                      }}
                    >
                      {option.label}
                    </option>
                  ))
                : propertyTypeOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      style={{
                        color: "black",
                      }}
                    >
                      {option.label}
                    </option>
                  ))}
            </Form.Control>
          </Col>

          <Col sm className="custom-input-group mb-3">
            <Form.Control
              as="select"
              name="temporal"
              value={filter.temporal}
              onChange={handleFilterChange}
              style={{
                textAlign: "center",
              }}
            >
              <option
                value=""
                style={{
                  color: "black",
                }}
              >
                Elegir temporalidad de inmueble
              </option>
              <option
                value="true"
                style={{
                  color: "black",
                }}
              >
                Sí
              </option>
              <option
                value="false"
                style={{
                  color: "black",
                }}
              >
                No
              </option>
            </Form.Control>
          </Col>

          <Col sm className="custom-input-group mb-3">
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              onChange={handleDropdownSelect3}
              defaultOptions
              value={
                filter.localidad === ""
                  ? "todos"
                  : propertyTypeOptions3.find(
                      (option) => option.value === filter.localidad
                    )
              }
              placeholder={"Elegir localidad de inmueble"}
              classNamePrefix="custom-select"
              className="mx-auto"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#fcd088",
                  primary: "black",
                },
              })}
              styles={{
                control: (provided) => ({
                  ...provided,
                  textAlign: "center",
                  color: "white",
                  borderColor: "black",
                  borderRadius: "0.25rem",
                  boxShadow: "none",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: 300,
                  overflowY: "auto",
                  backgroundColor: "white",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#fcd088" : "white",
                  color: state.isSelected ? "black" : "black",
                  fontWeight: state.isSelected ? "bold" : "normal",
                  "&:hover": {
                    backgroundColor: "#fcd088",
                    color: "black",
                    fontWeight: "bold",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "white",
                  fontWeight: "bold",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "white",
                }),
              }}
            />
          </Col>

          <h6 style={{ color: "#fcd088" }}>Condición </h6>

          <hr />

          <Row className="justify-content-md-center mt-2 text-center">
            <Col sm>
              <Form.Check
                type="switch"
                id="venta-switch"
                label="  Venta"
                name="venta"
                checked={filter.venta}
                onChange={handleFilterChange}
                bsPrefix="custom-form-check"
              />
            </Col>
            <Col sm>
              <Form.Check
                type="switch"
                id="alquiler-switch"
                label="  Alquiler"
                name="alquiler"
                checked={filter.alquiler}
                onChange={handleFilterChange}
                bsPrefix="custom-form-check"
              />
            </Col>
          </Row>
          {filter.moneda !== "" && (
            <Col sm>
              <h6 style={{ color: "#fcd088" }}>Precio Máximo</h6>
              <hr />
              <Row className="justify-content-md-center mt-2">
                <Col sm style={{ textAlign: "center" }}>
                  <span className="float-left">$ 0</span>
                </Col>
                <Col sm style={{ textAlign: "center" }}>
                  <RangeSlider
                    value={filter.precio || 0}
                    onChange={(e) =>
                      handleFilterChange({
                        target: { name: "precio", value: e.target.value },
                      })
                    }
                    style={{ color: "white" }}
                    min={0}
                    max={maxPrecio}
                    step={1000}
                    tooltipPlacement="top"
                    bsPrefix="form-range"
                  />
                </Col>
                <Col sm style={{ textAlign: "center" }}>
                  <span className="float-right">$ {maxPrecio || 0}</span>
                </Col>
              </Row>
              <Row className="justify-content-md-center mt-2">
                <Col>
                  <InputGroup className="custom-input-group mb-3">
                    <FormControl
                      value={filter.precio}
                      onChange={(e) =>
                        handleFilterChange({
                          target: { name: "precio", value: e.target.value },
                        })
                      }
                      placeholder="Valor maximo"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          )}

          <h6 style={{ color: "#fcd088" }}>Moneda </h6>
          {filter.moneda === "" && (
            <p style={{ fontSize: 12 }}>
              Cuando seleccione una moneda puede filtrar por precio máximo
            </p>
          )}
          <hr />

          <Row className="justify-content-md-center mt-2">
            <Col sm>
              <ButtonGroup className="d-flex">
                {propertyTypeMoneda.map((option) => (
                  <Button
                    key={option.value}
                    variant={filter.moneda === option.value ? "dark" : "dark"}
                    onClick={() =>
                      handleFilterChange({
                        target: { name: "moneda", value: option.value },
                      })
                    }
                    style={
                      filter.moneda === option.value
                        ? {
                            border: "0.5px solid grey",
                            backgroundColor: "#fcd088",
                          }
                        : {
                            border: "0.5px solid grey",
                          }
                    }
                  >
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          <Col sm>
            <h6 style={{ color: "#fcd088" }}>Superficie máxima</h6>
            <hr />

            <Row className="justify-content-md-center mt-2">
              <Col>
                <InputGroup className="custom-input-group mb-3">
                  <FormControl
                    value={filter.area}
                    onChange={(e) =>
                      handleFilterChange({
                        target: { name: "area", value: e.target.value },
                      })
                    }
                    placeholder="m² máximos"
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <h6 style={{ color: "#fcd088" }}>Habitaciones</h6>
          <hr></hr>
          <Row className="justify-content-md-center mt-2">
            <Col sm>
              <ButtonGroup className="d-flex">
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === ""
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === ""
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  Cualquiera
                </Button>
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === "0"
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "0" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === "0"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  0
                </Button>
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === "1"
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "1" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === "1"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  1
                </Button>
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === "2"
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "2" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === "2"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  2
                </Button>
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === "3"
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "3" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === "3"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  3
                </Button>
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === "4"
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "4" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === "4"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  4
                </Button>
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === "5"
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "5" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === "5"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  5
                </Button>
                <Button
                  variant={
                    isDormitoriosActive && filter.dormitorios === "6+"
                      ? "dark"
                      : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "dormitorios", value: "6+" },
                    })
                  }
                  style={
                    isDormitoriosActive && filter.dormitorios === "6+"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  6+
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <h6 style={{ color: "#fcd088" }}>Baños</h6>
          <hr></hr>
          <Row className="justify-content-md-center mt-2">
            <Col sm>
              <ButtonGroup className="d-flex">
                <Button
                  variant={
                    isBaniosActive && filter.banios === "" ? "dark" : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "banios", value: "" },
                    })
                  }
                  style={
                    isBaniosActive && filter.banios === ""
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  Cualquiera
                </Button>
                <Button
                  variant={
                    isBaniosActive && filter.banios === "1" ? "dark" : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "banios", value: "1" },
                    })
                  }
                  style={
                    isBaniosActive && filter.banios === "1"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  1
                </Button>
                <Button
                  variant={
                    isBaniosActive && filter.banios === "2" ? "dark" : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "banios", value: "2" },
                    })
                  }
                  style={
                    isBaniosActive && filter.banios === "2"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  2
                </Button>
                <Button
                  variant={
                    isBaniosActive && filter.banios === "3" ? "dark" : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "banios", value: "3" },
                    })
                  }
                  style={
                    isBaniosActive && filter.banios === "3"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  3
                </Button>
                <Button
                  variant={
                    isBaniosActive && filter.banios === "4" ? "dark" : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "banios", value: "4" },
                    })
                  }
                  style={
                    isBaniosActive && filter.banios === "4"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  4
                </Button>
                <Button
                  variant={
                    isBaniosActive && filter.banios === "5" ? "dark" : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "banios", value: "5" },
                    })
                  }
                  style={
                    isBaniosActive && filter.banios === "5"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  5
                </Button>
                <Button
                  variant={
                    isBaniosActive && filter.banios === "6+" ? "dark" : "dark"
                  }
                  onClick={() =>
                    handleFilterChange({
                      target: { name: "banios", value: "6+" },
                    })
                  }
                  style={
                    isBaniosActive && filter.banios === "6+"
                      ? {
                          border: "0.5px solid grey",
                          backgroundColor: "#fcd088",
                        }
                      : {
                          border: "0.5px solid grey",
                        }
                  }
                >
                  6+
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <h6 style={{ color: "#fcd088" }}>Dueño</h6>
          <hr></hr>

          <Row className="justify-content-md-center mt-2">
            <Col sm>
              <ButtonGroup className="d-flex">
                {propertyTypeUser.map((option) => (
                  <Button
                    key={option.value}
                    variant={
                      filter.tipousuario === option.value ? "dark" : "dark"
                    }
                    onClick={() =>
                      handleFilterChange({
                        target: { name: "tipousuario", value: option.value },
                      })
                    }
                    style={
                      filter.tipousuario === option.value
                        ? {
                            border: "0.5px solid grey",
                            backgroundColor: "#fcd088",
                          }
                        : {
                            border: "0.5px solid grey",
                          }
                    }
                  >
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>

          {filter.tipousuario === "Inmobiliaria" && (
            <>
              <Col sm className="custom-input-group mb-3">
                <AsyncSelect
                  loadOptions={loadOptions2}
                  onChange={handleDropdownSelect4}
                  defaultOptions
                  value={
                    filter.razonsocial === ""
                      ? "todos"
                      : razonesSociales.find(
                          (option) => option.value === filter.razonsocial
                        )
                  }
                  placeholder={"Elegir el agente inmobiliario"}
                  classNamePrefix="custom-select"
                  className="mx-auto"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#fcd088",
                      primary: "black",
                    },
                  })}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      textAlign: "center",
                      color: "black",
                      borderColor: "black",
                      borderRadius: "0.25rem",
                      boxShadow: "none",
                      fontWeight: "bold",
                      backgroundColor: "transparent",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: 300,
                      overflowY: "auto",
                      backgroundColor: "white",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#fcd088" : "white",
                      color: state.isSelected ? "black" : "black",
                      fontWeight: state.isSelected ? "bold" : "normal",
                      "&:hover": {
                        backgroundColor: "#fcd088",
                        color: "black",
                        fontWeight: "bold",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "white",
                      fontWeight: "bold",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                  }}
                />
              </Col>
            </>
          )}

          <h6 style={{ color: "#fcd088" }}>Adicionales</h6>
          <hr></hr>

          <Row className="justify-content-md-center mt-2">
            <Col sm>
              <Form.Check
                type="switch"
                id="cochera-switch"
                label="  Cochera"
                name="cochera"
                checked={filter.cochera}
                onChange={handleFilterChange}
                bsPrefix="custom-form-check"
              />
            </Col>
            <Col sm>
              <Form.Check
                type="switch"
                id="expensas-switch"
                label="  Expensas"
                name="expensas"
                checked={filter.expensas}
                onChange={handleFilterChange}
                bsPrefix="custom-form-check"
              />
            </Col>
            <Col sm>
              <Form.Check
                type="switch"
                id="mascotas-switch"
                label="  Mascotas"
                name="apto_mascotas"
                checked={filter.apto_mascotas}
                onChange={handleFilterChange}
                bsPrefix="custom-form-check"
              />
            </Col>
            <Col sm>
              <Form.Check
                type="switch"
                id="patio-switch"
                label="  Patio"
                name="patio"
                checked={filter.patio}
                onChange={handleFilterChange}
                bsPrefix="custom-form-check"
              />
            </Col>
            <Col sm>
              <Form.Check
                type="switch"
                id="balcon-switch"
                label="Balcon"
                name="balcon"
                checked={filter.balcon}
                onChange={handleFilterChange}
                bsPrefix="custom-form-check"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {loading ? (
        <div
          className="loader"
          style={{
            marginTop: "150px",
            marginBottom: "150px",
            backgrounColor: "#202529",
          }}
        ></div>
      ) : (
        <>
          {properties.length === 0 ? (
            <div
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                marginTop: "100px",
                marginBottom: "75px",
                backgroundColor: "#202529",
              }}
            >
              <img
                src={imagen} // Reemplaza esto con la ruta de tu imagen
                alt="Sin resultados"
                style={{ maxWidth: "150px" }}
              />
              <p style={{ fontSize: "18px", color: "white" }}>
                No se encontraron inmuebles para tu búsqueda.
              </p>
            </div>
          ) : (
            <>
              <Row className="tarjetas-propiedades">
                {propertiesToRender
                  .filter((property) => property.publicado) // Filtra solo las propiedades publicadas
                  .map((property, index) => (
                    <Col key={index}>
                      <PropertyCard id={property.id} property={property} />
                    </Col>
                  ))}
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Filtrado;
